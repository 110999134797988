import imgFooter from '../assets/images/dg_footer.png';

const Footer = () => {
  const Anio = new Date().getFullYear();
  return (
    <footer className='footer mt-5'>
      <div className='container-fluid px-0'>
        <img
          data-aos='fade-up'
          data-aos-anchor-placement='top-top'
          data-aos-offset='400'
          className='img-fluid'
          alt=""
          src={imgFooter}
        ></img>
        <p className='bg-black text-muted text-center px-5 py-3 m-0 small'>
          Copyright© 2003 – {Anio} DigiCatt Media. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
