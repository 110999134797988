import React, { useState } from 'react';
import axios from 'axios';

const M_TOKEN = process.env.REACT_APP_M_TOKEN;

const Form = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://api.digicatt.com.ec:8080/api/email',
        {
          to: email,
          subject: subject,
          text: message,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + M_TOKEN,
          },
        }
      );
      console.log(response.statusText);
    } catch (err) {
      console.error(err);
    }
    setEmail('');
    setMessage('');
    setSubject('');
  };

  return (
    <>
    <div id="Anchorform"></div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor='email'>Email:</label>
          <input type='email' id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label htmlFor='subject'>Subject:</label>
          <input type='text' id='subject' value={subject} onChange={(e) => setSubject(e.target.value)} />
        </div>
        <div>
          <label htmlFor='message'>Message:</label>
          <textarea id='message' value={message} onChange={(e) => setMessage(e.target.value)} />
        </div>
        <button type='submit'>Send Email</button>
      </form>
    </>
  );
};

export default Form;
